import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 50px 0",
	"background": "--color-dark",
	"quarkly-title": "Footer-13",
	"sm-padding": "50px 0 50px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "50px 30px 50px 30px",
			"background": "linear-gradient(180deg,rgba(0, 0, 0, 0.35) 0%,rgba(0, 0, 0, 0.35) 99%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://playandball.live/img/court_1.jpg) 0% 0% /cover repeat scroll padding-box",
			"display": "flex",
			"justify-content": "space-around",
			"align-items": "center",
			"margin": "0px 0px 80px 0px",
			"md-flex-direction": "column",
			"md-align-items": "stretch",
			"md-margin": "0px 0px 50px 0px",
			"md-padding": "30px 30px 30px 30px",
			"sm-padding": "20px 15px 20px 15px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "80%",
			"md-margin": "0px 0px 30px 0px",
			"md-width": "100%",
			"sm-margin": "0px 0px 18px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 400 42px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 15px 0px",
			"sm-margin": "0px 0px 12px 0px",
			"children": "Shipping & Support"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "normal 300 20px/1.5 --fontFamily-sans",
			"children": "We're committed to delivering your chosen gear right to your doorstep with efficiency and care. For detailed shipping information or any other inquiries, feel free to email us. Here at TerraGear, we bridge the gap between you and the world of football."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 80px 0px",
			"md-grid-template-columns": "1fr",
			"md-grid-gap": "36px 0",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Address"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": <>
				Unit 5 Hall Rd, Norwich NR4 6DH,{" "}
				<br />
				{" "}United Kingdom
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Contact"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:+44 800 842 0202",
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"hover-color": "#f5eceb",
			"children": "+44 800 842 0202"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:contact@playandball.live",
			"color": "#d7c7c5",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"display": "flex",
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"hover-color": "#f5eceb",
			"children": "contact@playandball.live"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "#ba653f",
			"md-margin": "0px 0px 20px 0px",
			"children": "Opening Hour"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "Mon to Fri (10 am – 6 pm)"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#d7c7c5",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": <>
				Sat (12 am – 6 pm){" "}
			</>
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"color": "#d7c7c5",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"text-align": "center",
			"children": "playandball.live 2023 © All rights reserved."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text4")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text5")} />
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
		</Box>
		<Text {...override("text8")} />
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;